import { create } from 'zustand';

type State = {
  locations: Location[];
};

export const useLocationStore = create<State>()(() => ({
  locations: [
    {
      id: 'l1',
      name: 'Hill Valley Gig Harbor',
    },
    {
      id: 'l2',
      name: 'Hill Valley Small Harbor',
    },
  ],
}));

type Location = {
  id: string;
  name: string;
};
