import { Filter, PaginatedResponse } from '@libs/models/common';
import { Talent } from '@libs/models/talents';
import { request } from '../request';

export type GetTalentsParams = {
  orderBy?: { field: string; sort: string }[];
  search?: string;
  page?: number;
  size?: number;
  filters?: {
    field: string;
    value: Filter['value'];
  }[];
};
export function getTalents(params?: GetTalentsParams) {
  const filters = params?.filters?.reduce<Record<string, string[] | string | number>>((acc, filter) => {
    if (typeof filter.value === 'object' && !Array.isArray(filter.value)) {
      return {
        ...acc,
        [`${filter.field}_from`]: [filter.value.from.toISOString()],
        [`${filter.field}_to`]: [filter.value.to.toISOString()],
      };
    }
    return { ...acc, [`${filter.field}_in`]: filter.value };
  }, {});
  return request.get<PaginatedResponse<TalentResponse[]>>('/v2/staff', {
    params: {
      pageNumber: params?.page,
      pageSize: params?.size,
      orderBy_in: params?.orderBy?.map((p) => {
        return `${p.field}_${p.sort}`;
      }),
      search: params?.search,
      ...filters,
    },
  });
}
type TalentResponse = Omit<Talent, 'lastWorkedTime'> & { lastWorkedTime: string };
