import { createBrowserRouter, Outlet, RouterProvider, useRouteError } from 'react-router-dom';
import { Flex } from '@atoms/layout';
import { AuthGuard } from '@molecules/auth/authGuard';
import { ErrorMessage } from '@organisms/dataDisplay';
import { ComingSoon } from '@organisms/dataDisplay/comingSoon';
import { MainLayout } from '@templates/mainLayout';

export function Routing() {
  return <RouterProvider router={router} />;
}

const router = createBrowserRouter([
  {
    path: '/login',
    async lazy() {
      const { SignInPage } = await import('@pages/auth');
      return { Component: SignInPage };
    },
  },
  {
    path: '/otp',
    async lazy() {
      const { OtpPage } = await import('@pages/auth');
      return { Component: OtpPage };
    },
  },
  {
    path: '/register',
    element: <Outlet />,
    children: [
      {
        path: '/register',
        async lazy() {
          const { AdminSignUp } = await import('@pages/auth');
          return { Component: AdminSignUp };
        },
      },
      {
        path: '/register/details',
        async lazy() {
          const { AdminSignUpDetails } = await import('@pages/auth');
          return { Component: AdminSignUpDetails };
        },
      },
      {
        path: '/register/address',
        async lazy() {
          const { AdminSignUpAddress } = await import('@pages/auth');
          return { Component: AdminSignUpAddress };
        },
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </AuthGuard>
    ),
    // TODO: make 404 page
    ErrorBoundary: () => {
      const error = useRouteError();

      return (
        <>
          <MainLayout>
            <ErrorMessage error={error as Error} />
          </MainLayout>
        </>
      );
    },
    children: [
      {
        path: '/',
        lazy: () => import('@pages/dashboard'),
      },
      {
        path: '/shifts',
        lazy: () => import('@pages/shifts'),
      },
      {
        path: '/talents',
        lazy: () => import('@pages/talents'),
      },
      {
        path: '/clients',
        lazy: () => import('@pages/clients'),
      },
      {
        path: '/settings',
        async lazy() {
          const { SettingsRoot } = await import('@pages/settings');
          return { Component: SettingsRoot };
        },
        children: [
          {
            path: '/settings/organization',
            async lazy() {
              const { OrganizationSettings } = await import('@pages/settings');
              return { Component: OrganizationSettings };
            },
            children: [
              {
                path: '/settings/organization/details',
                async lazy() {
                  const { OrganizationDetails } = await import('@pages/settings');
                  return { Component: OrganizationDetails };
                },
              },
            ],
          },
          {
            path: '/settings/*',
            element: (
              <Flex justifyCenter alignCenter height="100%" width="100%">
                <ComingSoon />
              </Flex>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    element: (
      <MainLayout>
        <ComingSoon />
      </MainLayout>
    ),
  },
]);
