import { create, StateCreator } from 'zustand';
import dayjs from 'dayjs';
import { activateTalents, createTalent, deactivateTalents, getTalents, type GetTalentsParams } from '@libs/api/talents';
import { Talent } from '@libs/models/talents';

type TalentsListState = {
  loading: boolean;
  initiated: boolean;
  showEditForm: boolean;
  page: number;
  total: number;
  perPage: number;
  load: (params?: GetTalentsParams) => Promise<void>;
  toggleEditForm: (value?: boolean) => void;
  talents: Talent[];
  selectedTalents: string[];
  setSelectedTalents: (talents: string[]) => void;
  deactivateTalents: (ids: string[]) => Promise<void>;
  activateTalents: (ids: string[]) => Promise<void>;
};

const createTalentsListSlice: StateCreator<TalentsListState, [], [], TalentsListState> = (set, get) => ({
  loading: false,
  initiated: false,
  showEditForm: false,
  load: async (params) => {
    set({
      loading: true,
    });
    const state = get();
    const { data } = await getTalents({
      page: state.page,
      size: state.perPage,
      ...params,
    });
    set({
      talents: data.items.map((t) => ({
        ...t,
        lastWorkedTime: dayjs(t.lastWorkedTime),
      })),
      total: data.totalCount,
      page: data?.pageNumber ?? 1,
      perPage: data?.pageSize ?? 10,
      initiated: true,
      loading: false,
    });
  },
  page: 1,
  perPage: 10,
  total: 0,
  toggleEditForm: (value) => {
    set({ showEditForm: value ? value : !get().showEditForm });
  },
  talents: [],
  selectedTalents: [],
  setSelectedTalents: (talents) => {
    set({ selectedTalents: talents });
  },
  deactivateTalents: async (ids: string[]) => {
    const state = get();
    await deactivateTalents(ids);
    set({
      talents: state.talents.map((t) => {
        if (ids.includes(t.id)) {
          return { ...t, staffingStatus: 'Deactivated' };
        }
        return t;
      }),
    });
  },
  activateTalents: async (ids: string[]) => {
    const state = get();
    await activateTalents(ids);
    // TODO: reload the list to get actual status
    set({
      talents: state.talents.map((t) => {
        if (ids.includes(t.id)) {
          return { ...t, staffingStatus: 'Active' };
        }
        return t;
      }),
    });
  },
});

type TalentsEditState = {
  createTalent: (data: Omit<Talent, 'id'>) => void;
  creating: boolean;
};
const createTalentsEditSlice: StateCreator<TalentsListState & TalentsEditState, [], [], TalentsEditState> = (
  set,
  get,
) => ({
  creating: false,
  createTalent: async (data) => {
    set({ creating: true });
    await createTalent(data);
    const state = get();
    await state.load({
      page: 0,
    });
    set({ creating: false });
  },
});

export const useTalentsStore = create<TalentsListState & TalentsEditState>()((...a) => ({
  ...createTalentsListSlice(...a),
  ...createTalentsEditSlice(...a),
}));
