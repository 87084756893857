import { Dayjs } from 'dayjs';

export type Shift = {
  id: string;
  shiftStart: Date;
  shiftLength: number;
  // TODO: check Intl when real statuses are available
  recurrence: 'single' | 'series' | 'rotation';
  location: {
    name: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
  };
  qualifications: string;
  specialty: string;
  // TODO: check Intl when real statuses are available
  status: 'assigned' | 'reAssigned' | 'pending' | 'inProgress' | 'completed' | 'canceled' | 'na';
  notes?: string;
  assignee: Assignee | null;
  locationDetails?: {
    department?: string;
    subDepartment?: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
  };
  mainScheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
  afterHoursScheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
};

type Assignee = {
  // TODO: check rendering when real avatars are available
  avatar: string;
  name: string;
  assignedByClient: boolean;
  specialty?: string;
};

export type ShiftType = 'day' | 'eve' | 'noc';

// Shifts starting between 4am and 11:59AM are categorized as day shifts.
// Shifts starting between 12pm and 7:59pm are designated as evening shifts.
// Shifts starting between 8pm and 3:59am are classified as night shifts.
export function getShiftType(date: Dayjs): ShiftType {
  if (date.hour() >= 4 && date.hour() < 12) {
    return 'day';
  }
  if (date.hour() >= 12 && date.hour() < 20) {
    return 'eve';
  }

  return 'noc';
}
